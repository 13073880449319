@media (max-width: 650px) {
    body {
        overflow-x: hidden;
    }
    h1 {
        font-size: 2.8rem;
        font-weight: 400;
    }

    h2 {
        font-size: 2.2rem;
        font-weight: 300;
    }

    h3 {
        font-size: 1.9rem;
        font-weight: 200;
    }

    h4 {
        font-size: 1.6rem;
        font-weight: 300;
    }

    h5 {
        font-size: 1.3rem;
        font-weight: 500;
    }

    p {
        font-size: 1rem;
        font-weight: 300;
    }

    strong {
        font-size: inherit;
        font-weight: 500;
    }

    a {
        font-size: 0.8rem;
    }

    .primary-btn {
        font-size: 0.8rem;
    }
    
    .landing {
        overflow-x: hidden;

        .container {
            @include flex-container;
            padding-inline: 20px;
        }

        .intro {
            min-height: 85vh;
            flex-wrap: wrap;
            row-gap: 20px;

            >* {
                padding-left: 0px;
            }

            .left-side {
                align-self: flex-end;
                flex-basis: 100%;

            }

            .right-side {
                padding-right: 0;
                justify-content: center;
            }
        }
    }

    .nav {
        height: 60px;

        >nav>ul {
            gap: 25px;

            .nav-accent-animation {
                padding-top: 8px;
            }
        }
    }

    .key-skills {
        min-height: 1200px;
        height: fit-content;

        .container .frame .skill-card {
            height: 340px;

            p {
                line-height: 1.7;
            }
        }
    }

    .library-toolset-section {
        margin-bottom: 40px;

        .container .frame {
            padding: 45px 30px;
            column-gap: 0;

            .skill-badge {
                width: 100px;
            }
        }
    }

    .about-me {
        .container .frame {
            padding-inline: 20px;
        }
    }

    .projects {
        .container .frame {
            padding-inline: 20px;

            .project-card {
                padding-inline: 0;

                .left-side> :first-child {
                    width: 100%;
                }

                .right-side {
                    .tags {
                        justify-content: center;

                        p {
                            font-size: 0.9rem;
                        }
                    }

                    .descr p {
                        text-align: center;
                        font-size: 0.9rem;
                        line-height: 1.8;
                    }
                }
            }
        }
    }

    .contact-me {
        .container .frame {
            padding-inline: 20px;
        }
    }
}