%coverup {
    content: ' ';
    position: absolute;
    inset: 0;
    z-index: 3;
    // test command
    background-image: linear-gradient(to right, $accent1 0%, $accent1 90%, transparent);
}


.hanging-box {
    background-color: $primaryBgColor;
    border-radius: 0 0 10px 10px;
    padding-inline: 20px;
}

//.greeting, .name, .subtext

.landing {
    .greeting::after {
        @extend %coverup;
    }
    .name::after {
        @extend %coverup;
    }
    .subtext::after {
        @extend %coverup;
    }

    .curtain-open-ele::after {
        animation: curtain-open 0.8s ease forwards;
    }   
}

@keyframes curtain-open {
    0% {
        transform-origin: 100% 100%;
        transform: scaleX(1);
    }
    100% {
        transform-origin: 100% 100%;
        transform: scaleX(0);
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bouncing-triangle {
    0% {
        transform: translate(0, 0px);
        // opacity: 100%;
    }

    90% {
        transform: translate(0, 3px);
        // opacity: 80%;
    }

    100% {
        transform: translate(0, 5px);
        // opacity: 50%;
    }
}

